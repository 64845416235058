import React, { useState } from "react";
import "./DateConverterTool.css"; // Import the css file for styling
import translations from "./translations";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { Helmet } from "react-helmet";
import DateConverter from "nepali-date-converter"; // Import the DateConverter class from the nepali-date-converter library
import moment from "moment-timezone";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAnglesDown } from '@fortawesome/free-solid-svg-icons'
import '@fortawesome/fontawesome-svg-core/styles.css';

library.add(faAnglesDown);

const DateConverterToolBox = ({ value, label, description, conversionModal }) => (
  <div className="date-converter-box">
    <label htmlFor={value} className="date-converter-label">
      {label}
    </label>
    <p className="date-converter-description">
      {description} {conversionModal}
    </p>
  </div>
);

const DateConverterTool = () => {
  const [result, setResult] = useState("");
  const [conversionType, setConversionType] = useState("");

  const [nepaliYear, setNepaliYear] = useState("2040");
  const [nepaliMonth, setNepaliMonth] = useState("1");
  const [nepaliDay, setNepaliDay] = useState("1");

  const [englishYear, setEnglishYear] = useState("1960");
  const [englishMonth, setEnglishMonth] = useState("1");
  const [englishDay, setEnglishDay] = useState("1");

  const nepaliMonths = [
    "Baishakh",
    "Jestha",
    "Ashad",
    "Shrawan",
    "Bhadra",
    "Ashwin",
    "Kartik",
    "Mangsir",
    "Poush",
    "Magh",
    "Falgun",
    "Chaitra",
  ];
  const englishMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleNepaliConvert = () => {
    const dateConverter = new DateConverter(
      +nepaliYear,
      +nepaliMonth - 1,
      +nepaliDay
    );
    const convertedDate = dateConverter.getAD();
    console.log(dateConverter);
    console.log(convertedDate);
    setResult(
      `${englishMonths[convertedDate.month]} ${convertedDate.date}, ${
        convertedDate.year
      } (AD)`
    );
    setConversionType(
      `${
        translations["english"].months[+nepaliMonth - 1]
      } ${+nepaliDay}, ${+nepaliYear} (BS)`
    );
  };

  const handleEnglishConvert = () => {
    const serverDate = new Date(+englishYear, +englishMonth - 1, +englishDay);
    console.log(serverDate);
    const targetCountryDate = moment
      .tz(serverDate, "Asia/Kathmandu")
      .utc(true)
      .toDate();

    var toNepDateObj = new DateConverter(
      new Date(
        targetCountryDate.getUTCFullYear(),
        targetCountryDate.getUTCMonth(),
        targetCountryDate.getUTCDate()
      )
    );
    const convertedDate = toNepDateObj.getBS();
    console.log(convertedDate);
    setResult(
      `${translations["english"].months[convertedDate.month]} ${
        convertedDate.date
      }, ${convertedDate.year} (BS)`
    );
    setConversionType(
      `${englishMonths[+englishMonth - 1]} ${+englishDay}, ${+englishYear} (AD)`
    );
  };

  const engToNepConversionModal = (
    <div className="date-converters-container">
      <div className="conversion-container">
        <div className="input-container">
          <label htmlFor="englishYear">Year (AD): </label>
          <select
            className="convert-dropdown"
            id="convert-year-dropdown"
            value={englishYear}
            onChange={(event) => setEnglishYear(event.target.value)}
          >
            {Array.from({ length: 74 }, (_, i) => i + 1960).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
          <label htmlFor="englishMonth">Month: </label>
          <select
            className="convert-dropdown"
            id="convert-month-dropdown"
            value={englishMonth}
            onChange={(event) => setEnglishMonth(event.target.value)}
          >
            {englishMonths.map((month, index) => (
              <option key={month} value={index + 1}>
                {month}
              </option>
            ))}
          </select>
          <label htmlFor="englishDay">Day: </label>
          <select
            className="convert-dropdown"
            id="convert-day-dropdown"
            value={englishDay}
            onChange={(event) => setEnglishDay(event.target.value)}
          >
            {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
              <option key={day} value={day}>
                {day}
              </option>
            ))}
          </select>
        </div>
        <button onClick={handleEnglishConvert}>Convert</button>
      </div>
    </div>
  );

  const nepToEngConversionModal = (
    <div className="date-converters-container">
      <div className="conversion-container">
        <div className="input-container">
          <label htmlFor="nepaliYear">Year (BS): </label>
          <select
            className="convert-dropdown"
            id="convert-year-dropdown"
            value={nepaliYear}
            onChange={(event) => setNepaliYear(event.target.value)}
          >
            {Array.from({ length: 51 }, (_, i) => i + 2040).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
          <label htmlFor="nepaliMonth">Month: </label>
          <select
            className="convert-dropdown"
            id="convert-month-dropdown"
            value={nepaliMonth}
            onChange={(event) => setNepaliMonth(event.target.value)}
          >
            {nepaliMonths.map((month, index) => (
              <option key={month} value={index + 1}>
                {month}
              </option>
            ))}
          </select>
          <label htmlFor="nepaliDay">Day: </label>
          <select
            className="convert-dropdown"
            id="convert-day-dropdown"
            value={nepaliDay}
            onChange={(event) => setNepaliDay(event.target.value)}
          >
            {Array.from({ length: 32 }, (_, i) => i + 1).map((day) => (
              <option key={day} value={day}>
                {day}
              </option>
            ))}
          </select>
        </div>
        <button onClick={handleNepaliConvert}>Convert</button>
      </div>
    </div>
  );

  return (
    <div>
      <div className="date-converters-container">
        <Helmet>
          <html lang="en" />
          <title>Nepali Date Converter, Online Nepali to English Date Converter</title>
          <meta
            name="keywords"
            content="nepali date to english date converter, nepali date to english, nepali date converter, nepali calendar converter, nepali birth date converter, online nepali date converter, english to nepali converter date, nepali english date converter"
          />
          <meta
            name="description"
            content="Convert any english date to nepali date using our date converter. Convert your english birth date to nepali date using our nepali date converter online. Use this free online tool to convert AD to BS (english to nepali date), or convert BS to AD (nepali date to english) date and time."
          />
        </Helmet>
        <DateConverterToolBox
          value="EnglishToNepali"
          label="Convert English to Nepali Date"
          description="Convert any date from AD to BS"
          conversionModal={engToNepConversionModal}
        />
        <DateConverterToolBox
          value="NepaliToEnglish"
          label="Convert Nepali to English Date"
          description="Convert any date from BS to AD"
          conversionModal={nepToEngConversionModal}
        />
      </div>
      <div className="result-container">
        {result ? (
          <div className="result">
            <span className="converted-date-text">
              {conversionType}
            </span>{" "}
            <span className="chevrons-down-arrow"> <i key={result} className="fa-solid fa-angles-down fa-1x fa-bounce" ></i></span>{" "}<br></br>
            <span className="actual-result">{result}</span>
          </div>
        ) : (
          <div className="result">
            <span className="results-place-text">
              Click 'Convert' and the results will be displayed here...
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default DateConverterTool;
