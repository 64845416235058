import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate.push(path);
    window.location.reload();
  };

  return (
    <div className="footer">
      <h3>Quick Links: </h3>
      <div className='footer-links'>
        <Link to="/" id='footer-link' onClick={() => handleClick("/")}>Home</Link>
        {' | '}
        <Link to="/dashain-2081" id='footer-link' onClick={() => handleClick("/dashain-2081")}>Dashain 2081</Link>
        {' | '}
        <Link to="/tihar-2081" id='footer-link' onClick={() => handleClick("/tihar-2081")}>Tihar 2081</Link>
        {' | '}
        <Link to="/new-year-2081" id='footer-link' onClick={() => handleClick("/new-year-2081")}>New Year 2081</Link>
      </div>
      <div className='copyright-text-contact'>
        <span className='copyright'>&copy; {new Date().getFullYear()} Copyright{' '}</span>
        <p className='text-dark'>
          nepalicalendar.online
          | &#9993; altnep60@icloud.com
        </p>
      </div>
    </div>
  );
};

export default Footer;
