import React from 'react';
import './DashainCalendar.css'; // Import the css file for styling
import DateConverter from 'nepali-date-converter'; // Import the DateConverter class from the nepali-date-converter library
import daysInMonth from './CalendarHelper';
import translations from './translations';
import moment from 'moment-timezone';
import eventsData from './eventsData.json';
import { MhahPanchang } from 'mhah-panchang';
import { Waypoint } from 'react-waypoint';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import '@fortawesome/fontawesome-svg-core/styles.css';
import { Helmet } from "react-helmet";

// Function to retrieve the events for the specified year and month
function getEventsForMonth(year, month) {
    month = Number(month);
    year = Number(year);
    var currentYearData = eventsData.years.find(yearData => yearData.year === year);
    var currentMonthData = currentYearData ? currentYearData.months.find(monthData => monthData.month === month) : undefined;
    var currentMonthDays = currentMonthData ? currentMonthData.days : [];
    return currentMonthDays;
}

// Create a MainCalendar react component
class DashainCalendar extends React.Component {
    // Set the initial state of the component
    state = {
        // Set the current Nepali month and year
        year: 2081,
        month: 6,
        // Set the default language to English
        language: 'english',
        greg: "greg",
        nepTime: new Date(),
        currentMonthDays: getEventsForMonth(2081, 6),
        showFlip: false
    };

    // Function to handle changes to the month or year dropdown
    handleChange = (event) => {
        // Update the state with the new month or year
        this.setState({ [event.target.name]: event.target.value }, () => {
            // Call the getEventsForMonth function after the state has been updated
            const currentMonthDays = getEventsForMonth(this.state.year, this.state.month);
            this.setState({ currentMonthDays });
        });
    }

    render() {

        // console.log(this.state.currentMonthDays);
        library.add(faCalendar);

        const festivalInfoText = () => {

            return (
                <span>
                    <div
                        className={
                            this.state.language === "nepali"
                                ? "nepali-festival-descr-font-size"
                                : "english-festival-descr-font-size"
                        }
                    >
                        <span className="festivalsText">
                        <span className={
                            this.state.language === "nepali"
                                ? "nepali-festival-name"
                                : "english-festival-name"
                        }
                    >{translations[this.state.language].texts["Dashain 2081"]}</span> {translations[this.state.language].texts["starts with Ghatasthapana on the month of Ashwin and falls on Ashwin 17. Vijaya Dashami (Dashain Tika) falls on Ashwin 26."]}
                        </span>
                    </div>
                </span>
            );
        };

        var festivalInfo = festivalInfoText();

        const dashainFestivalInfo = (
            <div className="festivalInfoBox">
                <span className="dashainFestivalInfo">
                <img src="./dashain.webp" alt="Kid Celebrating Dashain Festival" className="dashain-image"/>
                    {festivalInfo}
                </span>
            </div>
        );

        // Create the languageButtons element
        const languageButtons = (
            <div className="festival-language-buttons">
                <button className={this.state.language === 'english' ? 'lang-button selected' : 'lang-button'} onClick={this.handleChange} name="language" value="english">
                    English
                </button>
                <span className="lang-button-space"></span>
                <button className={this.state.language === 'nepali' ? 'lang-button selected' : 'lang-button'} onClick={this.handleChange} name="language" value="nepali">
                    Nepali
                </button>
            </div>
        );


        // Array of weekdays to be displayed at the top of the calendar
        const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        // Get the current Nepali month and year from the state
        var { year, month } = this.state;

        // Create a new DateConverter instance for the given year and month
        var dateConverter = new DateConverter(year, month - 1, 1);

        // Determine the first and last day of the month
        var firstDay = dateConverter.getDay();
        var lastDay = daysInMonth(year, month);

        //Create the weekday-row element
        const weekdayRow = (
            <tr className="weekday-row">
                {weekdays.map(weekday => (
                    <th className="weekday" key={weekday}>
                        <div
                            className={
                                this.state.language === "nepali"
                                    ? "nepali-weekday-font-size"
                                    : "english-weekday-font-size"
                            }
                        >
                            {translations[this.state.language].weekdays[weekday]}
                        </div>
                    </th>
                ))}
            </tr>
        );

        // Create the days-row elements
        const daysRows = [];

        const serverDate = new Date();
        const targetCountryDate = moment.tz(serverDate, "Asia/Kathmandu").utc(true).toDate();

        var todaysDateObj = new DateConverter(new Date(targetCountryDate.getUTCFullYear(), targetCountryDate.getUTCMonth(), targetCountryDate.getUTCDate()));
        var todaysMonth = todaysDateObj.getMonth();
        var todaysDay = todaysDateObj.getDate();
        var todaysYear = todaysDateObj.getYear();

        for (let i = 0; i < 6; i++) {
            // Create a row of days
            const days = [];

            for (let j = 0; j < 7; j++) {
                let date = i * 7 + j + 1 - firstDay;

                if (date < 1 || date > lastDay) {
                    days.push(<td key={`day-${i}-${j}`} className="day empty" />);
                } else {
                    // Create a new NepaliDate instance
                    const nepaliDateDisplayed = new DateConverter(this.state.year, this.state.month - 1, date);

                    // Convert the Nepali date to its equivalent Gregorian date
                    const gregorianDateDisplayed = nepaliDateDisplayed.getAD();

                    // Calculate tithi accurately based on Sunrise in KTM
                    const ktmDate = `${gregorianDateDisplayed.year}-${String(gregorianDateDisplayed.month + 1).padStart(2, "0")}-${String(gregorianDateDisplayed.date).padStart(2, "0")}`;

                    // Calculate Tithis for displaying in the equivalent day cell
                    const mhahObj = new MhahPanchang();
                    const mhahCalSunRiseTimer = mhahObj.sunTimer(new Date(ktmDate), 27.7172, 85.3240);
                    const tithiCalc = mhahObj.calendar(new Date(moment.tz(mhahCalSunRiseTimer.sunRise, "Asia/Kathmandu").utc(true).toDate().toISOString()), 27.7172, 85.3240);
                    const todaysTithi = tithiCalc.Tithi.name_en_IN;

                    const currentMonthDays = getEventsForMonth(this.state.year, this.state.month);

                    // check if the holidays array is not empty for the current day
                    const isHoliday = currentMonthDays.find(day => day.day === date && day.holidays.length !== 0);

                    days.push(
                        <td key={`day-${i}-${j}`} className={
                            (date === todaysDay && (Number(this.state.month - 1)) === (Number(todaysMonth)) && (Number(this.state.year)) === (Number(todaysYear)))
                                ? "active-day"
                                : "day"
                        }>
                            <div className={this.state.language === "nepali" ? "tithi-converted-day-nepali" : "tithi-converted-day"}>
                                <span className="tithi">{translations[this.state.language].tithis[todaysTithi]}</span>
                            </div>
                            <div className={`nepali-day ${isHoliday ? 'red-day' : ''}`}>{translations[this.state.language].days[date]}</div>
                            <div className="gregorian-converted-day">
                                <span className="gregorian-day">{gregorianDateDisplayed.date}</span>
                            </div>
                        </td>
                    );
                }
            }
            if (days.length > 0 && !days.every(day => day.props.className.includes("empty"))) {
                daysRows.push(<tr key={`days-row-${i}`}>{days}</tr>);
            }
        }

        const selectedMonthYear = (
            <div className="selected-month-year">
                {translations[this.state.language].months[this.state.month - 1]} {translations[this.state.language].years[this.state.year]}
            </div>
        );

        // Get the equivalent Gregorian date for the selected Nepali month and year
        const gregorianDate = dateConverter.getAD();

        // Create the equivalent Gregorian month and year element
        const gregorianMonthYear = (
            <div className="gregorian-month-year">
                {translations[this.state.greg].months[gregorianDate.month + 1]}/{translations[this.state.greg].months[gregorianDate.month + 2]} {gregorianDate.year}
            </div>
        );

        const importantDaysAndHolidays = (
            <div className="events-holidays-container">
                <h3>
                    <span className={
                        this.state.language === "nepali"
                            ? "nepali-holiday-dates-text"
                            : "english-holiday-dates-text"
                    }>
                        {translations[this.state.language].texts["Holidays and Festivals Dates for "]}
                        {translations[this.state.language].months[this.state.month - 1]} {translations[this.state.language].years[this.state.year]}:</span></h3>
                <ul>
                    {this.state.currentMonthDays.map(day => (
                        <Waypoint onEnter={() => this.setState({ showFlip: true })} onLeave={() => this.setState({ showFlip: false })}>
                            <li key={day.day}>
                                <span className="fa-layers fa-fw">
                                    <i className="fa-regular fa-calendar fa-2x fa-flip" style={{ animationPlayState: this.state.showFlip ? 'running' : 'paused' }}></i>
                                    <span className="fa-layers-text fa-inverse" data-fa-transform="shrink-800 down-300" style={{ fontWeight: '900' }}><span className="eventDates">{translations[this.state.language].days[day.day]}</span></span>
                                </span>
                                {day.events.concat(day.holidays).map((event, index) => (
                                    <span key={event} className={day.holidays.includes(event) ? "holiday" : "event"}>
                                        {event}
                                        {index < day.events.length + day.holidays.length - 1 ? ", " : ""}
                                    </span>
                                ))}
                            </li>
                        </Waypoint>
                    ))}
                </ul>
            </div>
        );

        // Return the react element for the MainCalendar component
        return (
            <div className="calendar">
                <Helmet>
                    <html lang="en" />
                    <title>Dashain 2080 - View Dashain 2080 Nepali Calendar Online</title>
                    <link rel="canonical" href="https://nepalicalendar.online/dashain-2081" />
                    <meta name="keywords" content="dashain 2081, when is dashain tika 2024, when is dashain in 2081, when is dashain in nepal 2024, dashain 2024, nepali calendar, nepali patro, online nepali calendar, online nepali patro, nepali calender, nepali calendar 2024, nepali calendar 2024, nepali calendar 2081, nepali calendar 2081, nepali calendar 2081, tihar 2081, nepali new year 2081, nepali date today 2081, nepali calendar with tithi, नेपाली पात्रो, नेपाली पात्रो 2081, 
  दशैं २०८1, nepali date today, bikram sambat calendar" />
                    <meta name="description"
                        content="Dashain 2081 important days and public holidays - Maha Ashtami, Maha Nawami, Vijaya Dashami 2081. Nepali Calendar online provides dates and calendars for all major Nepali festivals like Dashain, Tihar, Teej, Nepali New Year, etc. Available in this site - Nepali Patro 2081, Nepali Calendar 2081, Nepali Date Today 2081, Nepali Calendar 2024." />
                </Helmet>
                {languageButtons}
                {dashainFestivalInfo}
                <div className="nep-eng-month-year">
                    {selectedMonthYear}
                    {gregorianMonthYear}
                </div>
                <table className="calendar-table">
                    <thead>
                        {weekdayRow}
                    </thead>
                    <tbody>
                        {daysRows}
                    </tbody>
                </table>
                {importantDaysAndHolidays}
            </div>
        );
    };
};

export default DashainCalendar;