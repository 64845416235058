import React from 'react';
import './App.css';
import MainCalendar from './MainCalendar';
import Footer from './Footer';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import DashainCalendar from './DashainCalendar';
import TiharCalendar from './TiharCalendar';
import BaishakhCalendar from './BaishakhCalendar';
import DateConverterTool from './DateConverterTool';
import NavBar from './NavBar';
import { Link } from "react-router-dom";

function App() {

  return (
    <div className="container">
      <BrowserRouter>
      <NavBar />
      <h1>
      <Link to="/" className="title-click">
        <div style={{ display: 'block' }} className="header-wrapper">
          Nepali
          <br />
          Calendar
        </div>
      </Link>
      </h1>
      <h2 style={{ color: 'white' }}>Nepali Calendar (Nepali Patro) showing dates, tithis, important events, festivals in Nepal. Nepali Calendar for Dashain 2081, Dashain 2081, Tihar 2081, Nepali New Year and more.</h2>
      <p>नेपाली पात्रो
      </p>
        <Routes>
          <Route path='/' element={<MainCalendar />} />
          <Route path='/dashain-2081' element={<DashainCalendar />} />
          <Route path='/tihar-2081' element={<TiharCalendar />} />
          <Route path='/new-year-2081' element={<BaishakhCalendar />} />
          <Route path='/date-converter' element={<DateConverterTool />} />
          <Route path='*' element={<MainCalendar />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  )
}
export default App;