import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "./GoToLinks.css";

const GoToLinks = () => {

  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate.push(path);
    window.location.reload();
  };

  return (
    <div className="go-to-links-container">
      <div className="go-to-links-label" id="go-to-links-text">
        View Calendar For:
      </div>
      <div className="go-to-links-list" id="go-to-links-list">
        <Link to="/dashain-2081" className="NavItems" id="go-to-link-item" onClick={() => handleClick("/dashain-2081")}>
          <img
            src="./dashain.webp"
            alt="Kid Celebrating Dashain Festival"
            className="dashain-icon"
          />{" "}
          Dashain 2081
        </Link>
        <Link to="/tihar-2081" className="NavItems" id="go-to-link-item" onClick={() => handleClick("/tihar-2081")}>
          <img
            src="./tihar.webp"
            alt="Lights in Tihar Festival"
            className="tihar-icon"
          />{" "}
          Tihar 2081
        </Link>
        <Link to="/new-year-2081" className="NavItemLast" id="go-to-link-item" onClick={() => handleClick("/new-year-2081")}>
          <img
            src="./fireworks.webp"
            alt="Fireworks in the sky"
            className="new-year-icon"
          />{" "}
          New Year 2081
        </Link>
      </div>
    </div>
  );
};

export default GoToLinks;
