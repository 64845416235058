import React, { useState } from "react";
import "./NavBar.css";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars, faHome } from '@fortawesome/free-solid-svg-icons'
import '@fortawesome/fontawesome-svg-core/styles.css';

export default function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  library.add(faBars);
  library.add(faHome);

  const handleStateChange = (state) => {
    setIsOpen(state.isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <div className="NavBar">
      <div className="burgerVisible">
        <Menu right isOpen={isOpen} onStateChange={handleStateChange} className={ "menu" } customBurgerIcon={ <span className="menu-icon">
                                    <i className="fa-solid fa-bars fa-10x fa-bounce" ></i>
                                </span> }>
        <Link to="/" className="NavItem1" onClick={closeMenu}>
        <span className="home-icon">
                                    <i className="fa-solid fa-house-user fa-1x" ></i>
                                </span>Home
        </Link>
        <Link to="/dashain-2081" className="NavItems" onClick={closeMenu}>
        <img src="./dashain.webp" alt="Kid Celebrating Dashain Festival" className="dashain-icon"/>  Dashain 2081
        </Link>
        <Link to="/tihar-2081" className="NavItems" onClick={closeMenu}>
        <img src="./tihar.webp" alt="Lights in Tihar Festival" className="tihar-icon"/>  Tihar 2081
        </Link>
        <Link to="/new-year-2081" className="NavItemLast" onClick={closeMenu}>
        <img src="./fireworks.webp" alt="Fireworks in the sky" className="new-year-icon"/>  New Year 2081
        </Link>
        <Link to="/date-converter" className="NavItemLast" onClick={closeMenu}>
        <img src="./date-converter-tool.webp" alt="Tools" className="new-year-icon"/>  Date Converter
        </Link>
        </Menu>
      </div>
    </div>
  );
}
