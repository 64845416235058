const translations = {
    nepali: {
        months: {
            "0": "बैशाख",
            "1": "जेठ",
            "2": "असार",
            "3": "श्रावण",
            "4": "भाद्र",
            "5": "अश्विन",
            "6": "कार्तिक",
            "7": "मंसिर",
            "8": "पौष",
            "9": "माघ",
            "10": "फाल्गुन",
            "11": "चैत्र"
        },
        weekdays: {
            "Sun": "आईत",
            "Mon": "सोम",
            "Tue": "मंगल",
            "Wed": "बुध",
            "Thu": "बिहि",
            "Fri": "शुक्र",
            "Sat": "शनि"
        },
        days: {
            "1": "१",
            "2": "२",
            "3": "३",
            "4": "४",
            "5": "५",
            "6": "६",
            "7": "७",
            "8": "८",
            "9": "९",
            "10": "१०",
            "11": "११",
            "12": "१२",
            "13": "१३",
            "14": "१४",
            "15": "१५",
            "16": "१६",
            "17": "१७",
            "18": "१८",
            "19": "१९",
            "20": "२०",
            "21": "२१",
            "22": "२२",
            "23": "२३",
            "24": "२४",
            "25": "२५",
            "26": "२६",
            "27": "२७",
            "28": "२८",
            "29": "२९",
            "30": "३०",
            "31": "३१",
            "32": "३२"
        },
        years: {
            "2070": "२०७०",
            "2071": "२०७१",
            "2072": "२०७२",
            "2073": "२०७३",
            "2074": "२०७४",
            "2075": "२०७५",
            "2076": "२०७६",
            "2077": "२०७७",
            "2078": "२०७८",
            "2079": "२०७९",
            "2080": "२०८०",
            "2081": "२०८१",
            "2082": "२०८२",
            "2083": "२०८३",
            "2084": "२०८४",
            "2085": "२०८५",
            "2086": "२०८६",
            "2087": "२०८७",
            "2088": "२०८८",
            "2089": "२०८९"
        },
        tithis: {
            "Ashtami": "अष्टमी",
            "Navami": "नवमी",
            "Dasami": "दशमी",
            "Ekadasi": "एकादशी",
            "Dvadasi": "द्वादशी",
            "Trayodasi": "त्रयोदशी",
            "Chaturdasi": "चतुर्दशी",
            "Amavasya": "औंसी",
            "Padyami": "प्रतिपदा",
            "Vidhiya": "द्दितीया",
            "Chavithi": "चतुर्थी",
            "Panchami": "पंचमी",
            "Shasti": "षष्ठी",
            "Sapthami": "सप्तमी",
            "Punnami": "पुर्णिमा",
            "Thadiya": "तृतीया",
            "Chaviti": "चतुर्थी"
        },
        texts: {
            "Today": "आज",
            "Holidays and Festivals Dates for ": "बिदा र महत्त्वपूर्ण मितिहरू - ",
            "Dashain 2081": "दशैं २०८१",
            "Tihar 2081": "तिहार २०८१",
            "New Year 2081": "नयाँ वर्ष २०८१",
            "starts with Ghatasthapana on the month of Ashwin and falls on Ashwin 17. Vijaya Dashami (Dashain Tika) falls on Ashwin 26.": "आश्विन महिनाको घटस्थापनाबाट सुरु भई आश्विन १७ गते पर्छ। विजया दशमी (दशैं टिका) आश्विन २६ गते पर्छ।",
            "starts with Kaag Tihar on the month of Kartik and falls on Kartik 14. Laxmi Puja / Kukur Tihar falls on Kartik 15, Gai Tihar on Kartik 17, Mha Puja falls on Kartik 17 and Bhai Tika falls on Kartik 18.": "कात्तिक महिनामा काग तिहारबाट सुरु हुन्छ र कात्तिक २५ मा पर्छ। लक्ष्मी पूजा/कुकुर तिहार कार्तिक २६ गते, गाई तिहार कार्तिक २७ मा, म्हा पूजा कार्तिक २८ मा र भाइटीका २९ गते पर्दछ।",
            "Scroll down to view Nepali monthly calendar for the year 2080 Bikram Sambat. You can also view important holidays, festivals and events for each month down below.": "विक्रम संवत २०८० को नेपाली मासिक पात्रो हेर्न तल स्क्रोल गर्नुहोस्। तपाइँ तल प्रत्येक महिनाको लागि महत्त्वपूर्ण बिदा, चाडपर्व र घटनाहरू पनि हेर्न सक्नुहुन्छ।"
        }
    },
    english: {
        months: {
            "0": "Baisakh",
            "1": "Jestha",
            "2": "Asar",
            "3": "Shrawan",
            "4": "Bhadra",
            "5": "Ashwin",
            "6": "Kartik",
            "7": "Mangsir",
            "8": "Poush",
            "9": "Magh",
            "10": "Falgun",
            "11": "Chaitra"
        },
        weekdays: {
            "Sun": "Sun",
            "Mon": "Mon",
            "Tue": "Tue",
            "Wed": "Wed",
            "Thu": "Thu",
            "Fri": "Fri",
            "Sat": "Sat"
        },
        days: {
            "1": "1",
            "2": "2",
            "3": "3",
            "4": "4",
            "5": "5",
            "6": "6",
            "7": "7",
            "8": "8",
            "9": "9",
            "10": "10",
            "11": "11",
            "12": "12",
            "13": "13",
            "14": "14",
            "15": "15",
            "16": "16",
            "17": "17",
            "18": "18",
            "19": "19",
            "20": "20",
            "21": "21",
            "22": "22",
            "23": "23",
            "24": "24",
            "25": "25",
            "26": "26",
            "27": "27",
            "28": "28",
            "29": "29",
            "30": "30",
            "31": "31",
            "32": "32"
        },
        years: {
            "2070": "2070",
            "2071": "2071",
            "2072": "2072",
            "2073": "2073",
            "2074": "2074",
            "2075": "2075",
            "2076": "2076",
            "2077": "2077",
            "2078": "2078",
            "2079": "2079",
            "2080": "2080",
            "2081": "2081",
            "2082": "2082",
            "2083": "2083",
            "2084": "2084",
            "2085": "2085",
            "2086": "2086",
            "2087": "2087",
            "2088": "2088",
            "2089": "2089"
        },
        tithis: {
            "Ashtami": "Ashtami",
            "Navami": "Navami",
            "Dasami": "Dashami",
            "Ekadasi": "Ekadashi",
            "Dvadasi": "Dwadashi",
            "Trayodasi": "Trayoda..",
            "Chaturdasi": "Chaturda..",
            "Amavasya": "Aaunsi",
            "Padyami": "Pratipada",
            "Vidhiya": "Dwitiya",
            "Chavithi": "Chaturthi",
            "Panchami": "Panchami",
            "Shasti": "Sasthi",
            "Sapthami": "Saptami",
            "Punnami": "Purnima",
            "Thadiya": "Tritiya",
            "Chaviti": "Chaturthi"
        },
        texts: {
            "Today": "Today",
            "Holidays and Festivals Dates for ": "Holidays and Festivals Dates for ",
            "Dashain 2081": "Dashain 2081",
            "Tihar 2081": "Tihar 2081",
            "New Year 2081": "New Year 2081",
            "starts with Ghatasthapana on the month of Ashwin and falls on Ashwin 17. Vijaya Dashami (Dashain Tika) falls on Ashwin 26.": "starts with Ghatasthapana on the month of Ashwin and falls on Ashwin 17. Vijaya Dashami (Dashain Tika) falls on Ashwin 26.",
            "starts with Kaag Tihar on the month of Kartik and falls on Kartik 14. Laxmi Puja / Kukur Tihar falls on Kartik 15, Gai Tihar on Kartik 17, Mha Puja falls on Kartik 17 and Bhai Tika falls on Kartik 18.": "starts with Kaag Tihar on the month of Kartik and falls on Kartik 14. Laxmi Puja / Kukur Tihar falls on Kartik 15, Gai Tihar on Kartik 17, Mha Puja falls on Kartik 17 and Bhai Tika falls on Kartik 18.",
            "Scroll down to view Nepali monthly calendar for the year 2081 Bikram Sambat. You can also view important holidays, festivals and events for each month down below.": "Scroll down to view Nepali monthly calendar for the year 2081 Bikram Sambat. You can also view important holidays, festivals and events for each month down below."
        }
    },
    greg: {
        months: {
            "1": "Jan",
            "2": "Feb",
            "3": "Mar",
            "4": "Apr",
            "5": "May",
            "6": "Jun",
            "7": "Jul",
            "8": "Aug",
            "9": "Sep",
            "10": "Oct",
            "11": "Nov",
            "12": "Dec"
        }
    }
}

export default translations;